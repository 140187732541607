var Presence = require('./presence');
var LocalDocPresence = require('./local-doc-presence');
var RemoteDocPresence = require('./remote-doc-presence');

function DocPresence(connection, collection, id) {
  var channel = DocPresence.channel(collection, id);
  Presence.call(this, connection, channel);

  this.collection = collection;
  this.id = id;
}
module.exports = DocPresence;

DocPresence.prototype = Object.create(Presence.prototype);

DocPresence.channel = function(collection, id) {
  return collection + '.' + id;
};

DocPresence.prototype._createLocalPresence = function(id) {
  return new LocalDocPresence(this, id);
};

DocPresence.prototype._createRemotePresence = function(id) {
  return new RemoteDocPresence(this, id);
};
